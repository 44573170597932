import React from 'react';
import Container from '@/components/common/container';
import CtaLink from '@/components/common/cta-link-v2';
import { GatsbyImage } from 'gatsby-plugin-image';
import Fade from '@/components/common/fade';
import Marquee from 'react-fast-marquee';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

const Hero = ({ title, subtitle, images, ctaLink }) => {
  return (
    <div className="pb-8 pt-16 md:pb-12 md:pt-20">
      <Container>
        <Fade>
          <div className="flex flex-col items-center text-center">
            <h1 className="text-3xl md:text-[40px] lg:text-[56px]">
              <span className="bg-gradient-to-br from-orangey-yellow via-reddish-magenta to-purply-blue bg-clip-text font-bold text-transparent antialiased">
                We are
              </span>{' '}
              Galileo
            </h1>
            <p className="font-outfit mt-5 max-w-[650px] font-light md:text-lg lg:max-w-[690px]">
              {subtitle}
            </p>
            {ctaLink && (
              <div className="mt-5">
                <CtaLink
                  label={ctaLink.label}
                  bg="bg-white bg-opacity-[.96]"
                  className="min-w-[170px]"
                  url="https://ats.rippling.com/galileo/jobs"
                  isExternal={true}
                />
              </div>
            )}
          </div>
        </Fade>
      </Container>

      <div className="mt-8 md:mt-14">
        <Marquee
          direction="right"
          pauseOnHover={true}
          gradient={false}
          speed={50}
        >
          <div className="ml-[0px] w-full">
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 6 }}
            >
              <Masonry gutter={20}>
                {images.map((image, index) => {
                  return (
                    <div key={index} className="my-auto">
                      <GatsbyImage
                        alt=""
                        className="rounded-[20px]"
                        image={image.asset.gatsbyImageData}
                      />
                    </div>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export default Hero;
