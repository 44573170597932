import React from 'react';
import Container from '@/components/common/container';
import { GatsbyImage } from 'gatsby-plugin-image';
import Fade from '@/components/common/fade';

const Benefits = ({ title, subtitle, benefits }) => {
  return (
    <div className="bg-[url(/benefits.svg)] bg-[length:300px] bg-[left_15rem] bg-no-repeat md:bg-[length:400px] xl:bg-[length:unset]">
      <div className="pb-16 lg:pb-28">
        <Container>
          <Fade>
            <div className="text-center">
              <h2 className="text-[28px] md:text-[32px] lg:text-[40px]">
                {title}
              </h2>
              <p className="mx-auto mt-2 max-w-[640px] text-lg font-light">
                {subtitle}
              </p>
            </div>
          </Fade>

          <Fade>
            <div className="mt-16 grid grid-cols-1 gap-10 md:mt-24 md:grid-cols-2 lg:grid-cols-3">
              {benefits.map(({ description, title, icon }, index) => {
                return (
                  <div
                    key={index}
                    className="mx-auto max-w-[280px] text-center md:text-left"
                  >
                    <div>
                      <GatsbyImage
                        alt=""
                        className="h-12 w-12"
                        image={icon.asset.gatsbyImageData}
                      />
                    </div>
                    <h3 className="mt-4 text-xl lg:mt-6">{title}</h3>
                    <p className="mt-2 font-light text-gray-500">
                      {description}
                    </p>
                  </div>
                );
              })}
            </div>
          </Fade>
        </Container>
      </div>
    </div>
  );
};

export default Benefits;
