import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

type profilePictureProps = {
  imageData?: IGatsbyImageData;
  bgRingDirection: 'topRight' | 'bottomRight';
  isLocal?: boolean;
  LocalImage?: any;
  size?: 'sm' | 'lg';
};

const getSize = (size: 'sm' | 'lg') => {
  switch (size) {
    case 'sm':
      return 'w-[100px] h-[100px]';
    case 'lg':
      return 'w-24 h-24';
    default:
      return 'w-[125px] h-[125px] lg:h-[175px] lg:w-[175px]';
  }
};

const ProfilePicture = ({
  imageData,
  bgRingDirection,
  isLocal,
  LocalImage,
  size,
}: profilePictureProps) => {
  return (
    <div className="py-3 pr-3">
      <div className="relative">
        <div
          className={`absolute left-3 rounded-full bg-gradient-to-br from-orangey-yellow via-reddish-magenta to-purply-blue p-[2px] ${getSize(size)} ${bgRingDirection === 'topRight' ? '-top-2' : ''} ${bgRingDirection === 'bottomRight' ? 'top-2' : ''} `}
        >
          <div className="h-full w-full rounded-full bg-magnolia"></div>
        </div>

        {imageData && (
          <GatsbyImage
            alt="profile"
            image={imageData}
            className={`z-20 rounded-full ${getSize(size)} `}
          />
        )}

        {isLocal && (
          <LocalImage className={`relative z-20 ${getSize(size)} `} />
        )}
      </div>
    </div>
  );
};

export default ProfilePicture;
