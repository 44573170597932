import React from 'react';
import Container from '@/components/common/container';
import ProfilePicture from '@/components/common/profile-picture';
import CtaLink from '@/components/common/cta-link';
import ProfileIcon from '@/assets/profile-icon.svg';
import AnchorIcon from '@/assets/anchor-icon.svg';
import Fade from '@/components/common/fade';

const isEven = (num: number) => num % 2 === 0;

const TeamMembers = ({
  teamMembersTitle,
  teamMembers,
  ctaLink,
  advisors,
  advisorsTitle,
}) => {
  return (
    <div className="bg-[url(/galileans.svg)] bg-[length:70%] bg-right-top bg-no-repeat pb-24 pt-16 md:bg-[length:60%] md:pt-20 lg:pb-36 xl:bg-[length:unset]">
      <Container>
        <div>
          <Fade>
            <h2 className="text-center text-2xl md:text-[32px] lg:text-[40px]">
              {teamMembersTitle}
            </h2>
          </Fade>

          <div className="mt-10 grid auto-rows-auto grid-cols-2 justify-center gap-6 gap-y-12 md:mt-20 md:grid-cols-3 lg:grid-cols-4">
            {teamMembers.map(
              ({ role, name, link, country, bio, image }, index) => {
                return (
                  <Fade key={index}>
                    <div className="flex flex-col items-center text-center">
                      <ProfilePicture
                        imageData={image.asset.gatsbyImageData}
                        bgRingDirection={
                          isEven(index) ? 'topRight' : 'bottomRight'
                        }
                      />

                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mb-2 mt-4 flex items-center gap-2 font-semibold text-purply-blue transition-colors duration-300 hover:text-violet-500 md:text-lg"
                      >
                        <span>{name}</span>
                        <span>
                          <AnchorIcon />
                        </span>
                      </a>

                      <span className="mt-autoc text-sm text-pearl-black md:text-base">
                        {role}
                      </span>
                      <span className="text-gray-500">{country}</span>
                      <p className="fount-outfit mt-2 text-xs font-light text-gray-500 md:text-sm">
                        {bio}
                      </p>
                    </div>
                  </Fade>
                );
              },
            )}
            <Fade>
              <div className="flex flex-col items-center">
                <ProfilePicture
                  LocalImage={ProfileIcon}
                  isLocal
                  bgRingDirection="topRight"
                />
                <div className="mt-3">
                  <CtaLink
                    label={ctaLink.label}
                    bg="magnolia"
                    url="https://ats.rippling.com/galileo/jobs"
                    isExternal={true}
                  />
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TeamMembers;
